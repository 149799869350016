import { isNil } from "lodash";

import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { IPurchaseState } from "@smartrr/shared/entities/PurchaseState";

import { IPurchasableVariant } from "../../entities/PurchasableVariant";
import { ProductVariantInventoryPolicy } from "../../shopifyGraphQL/api";
import { shopifyGidToNumber } from "../ensureShopifyGid";

// Out of Stock
export function isVariantInStock(variant: IPurchasableVariant | undefined | null, quantity = 1) {
  if (!variant || isNil(variant.inventoryQuantity) || isNil(variant.inventoryPolicy)) {
    // if the product info isn't streamed in yet we can't do this check
    return true;
  }

  return !(
    variant.inventoryQuantity - quantity < 0 && variant.inventoryPolicy === ProductVariantInventoryPolicy.Deny
  );
}

// Draft, Archived, Deleted - Variants
export function isVariantDeleted(variant: IPurchasableVariant | undefined | null) {
  if (!variant) {
    return false;
  }

  return !variant.isActiveInShopify || Boolean(variant.deletedAt) || variant.isDraftOrArchived;
}

export function isPurchasableDeleted(purchasable: IPurchasable | undefined | null) {
  if (!purchasable) {
    return false;
  }
  return !purchasable.isActiveInShopify || Boolean(purchasable.deletedAt) || purchasable.isDraftOrArchived;
}

export const getStLineItemsVariantsIds = (purchaseState: IPurchaseState): number[] => {
  if (purchaseState.stLineItems.length) {
    return [
      ...new Set(
        purchaseState.stLineItems
          .filter(item => item.vnt.shopifyId)
          .map(item => shopifyGidToNumber(item.vnt.shopifyId!))
      ),
    ];
  }
  return [];
};
